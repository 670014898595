import Head from "next/head";

const PageHead = ({ title }) => {

  const formattedTitle = Array.isArray(title) ? title.join(' ') : title;

  return (
    <>
      <Head>
        <title>{formattedTitle} || CyberAgric - An AI Powered Agriculture App</title>
        <meta name="description" content="CyberAgric leverages artificial intelligence to provide advanced agricultural solutions" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </>
  );
};

export default PageHead;
